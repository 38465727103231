<template>
  <div>
    <b-row class="no-gutters px-3 px-sm-0">
      <b-col md="8">
        <h1 class="mr-sm-4 header-tablepage">Setting</h1>
      </b-col>
    </b-row>
    <b-container class="no-gutters bg-white mt-3">
      <div class="pt-3">
        <InputText placeholder="Search Menu" v-model="search" />
      </div>
      <b-row>
        <b-col
          v-for="(items, index) of filterItems"
          cols="6"
          class="pointer my-3"
          @click.prevent="$router.push(items.path)"
          :key="index + '-' + items.title"
        >
          <div class="bg-icon shadow-sm">
            <font-awesome-icon :icon="items.icon" size="lg" />
          </div>
          <div class="content">
            <div class="ft-weight" style="text-transform: uppercase">
              {{ items.title }}
            </div>
            <div class="ft-10">{{ items.desc }}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      menu: [
        {
          title: "MemberShip",
          desc: "การตั้งค่ารหัสสมาชิก, ระดับสมาชิก , Member Prefix ID",
          path: "/setting/membership",
          icon: "copy",
        },
        {
          title: "line setting",
          desc: "การตั้งค่าข้อเสนอและนโยบายของระบบ",
          path: "/setting/line",
          icon: "lock",
        },
        {
          title: "Language",
          desc: "การตั้งค่า Language",
          path: "/setting/Language",
          icon: "language",
        },
        {
          title: "theme",
          desc: "การปรับรูปแบบอัตลักษณ์, สี, รูปแบบอักษร, ทั้งหมดของระบบ",
          path: "/setting/theme",
          icon: "palette",
        },
        {
          title: "chart theme",
          desc: "การปรับสีของกราฟทั้งหมดในระบบ",
          path: "/setting/chart-theme",
          icon: "chart-bar",
        },
        {
          title: "Business Information",
          desc: "การตั้งค่าและเก็บข้อมูลสาขา, ข้อมูลที่ตั้งสาขา, พนักงานในร้าน",
          path: "/setting/businessinfo",
          icon: "info-circle",
        },

        {
          title: "email template",
          desc: "การตั้งค่าระบบอีเมลเทมเพลต",
          path: "/setting/email-template",
          icon: "file-invoice",
        },

        {
          title: "email signature",
          desc: "การตั้งค่า Email Signature",
          path: "/setting/email-signature",
          icon: "file-invoice",
        },
        {
          title: "E-Commerce",
          desc: "เชื่อมต่อระบบ Marketplace",
          path: "/setting/ecommerce",
          icon: "file-invoice",
        },

        {
          title: "Personal Customer",
          desc: "ตั้งค่าการลงทะเบียนแบบ Personal Customer (ลูกค้าปกติ)",
          path: "/setting/personal",
          icon: "user-cog",
        },
        {
          title: "Company Customer",
          desc: "ตั้งค่าการลงทะเบียนแบบ Company Customer (ลูกค้าแบบบริษัท, ร้านค้า)",
          path: "/setting/company",
          icon: "user-cog",
        },
        {
          title: "Shipping",
          desc: "ตั้งค่าขนส่ง",
          path: "/setting/shipping",
          icon: "truck",
        },
        {
          title: "Address",
          desc: "ตั้งค่ารายละเอียดที่อยู่",
          path: "/setting/address",
          icon: "address-book",
        },
        {
          title: "SOCIAL MEDIA",
          desc: "ตั้งค่ารายละเอียดโซเซียลมีเดีย",
          path: "/setting/socialmedia",
          icon: "mobile-alt",
        },
        {
          title: "Customer Type",
          desc: "ตั้งค่าประเภทลูกค้า",
          path: "/setting/customer-type",
          icon: "mobile-alt",
        },
        {
          title: "Customer Group",
          desc: "ตั้งค่ารายละเอียดกลุ่มลูกค้า",
          path: "/setting/customer-group",
          icon: "users",
        },
        {
          title: "Booking",
          desc: "จัดการระบบ Booking",
          path: "/setting/booking",
          icon: "calendar-check",
        },
        {
          title: "Term and condition",
          desc: "รายละเอียดเงื่อนไขและนโยบาย",
          path: "/update/term",
          icon: "file-invoice",
        },
        {
          title: "PRIVACY POLICY",
          desc: "รายละเอียดการตั้งค่าความเป็นส่วนตัว",
          path: "/update/policy",
          icon: "file-invoice",
        },

        {
          title: "Payment Type",
          desc: "รูปแบบการชำระเงิน",
          path: "/setting/payment-type",
          icon: "money-bill",
        },

        {
          title: "Branch Setting",
          desc: "การตั้งค่า Branch",
          path: "/setting/branch-setting",
          icon: "shopping-bag",
        },
        {
          title: "Business Hour",
          desc: "ตั้งค่า Business Hour",
          path: "/setting/business-hour",
          icon: "users",
        },
        {
          title: "Product Custom Field",
          desc: "ตั้งค่าสินค้า",
          path: "/setting/product-field",
          icon: "table",
        },

        {
          title: "Age Group",
          desc: "กลุ่มอายุ",
          path: "/setting/age-group",
          icon: "registered",
        },
        {
          title: "Report",
          desc: "ตั้งค่ารายงาน",
          path: "/setting/report",
          icon: "file",
        },

        {
          title: "Plugins",
          desc: "จัดการปลั๊กอิน",
          path: "/setting/plugins",
          icon: "puzzle-piece",
        },

        {
          title: "Receipt",
          desc: "การตั้งค่าใบเสร็จ",
          path: "/setting/receipt-menu",
          icon: "receipt",
        },
        {
          title: "Communication Channel Usage",
          desc: "การตั้งค่าการใช้ช่องทางการสื่อสาร",
          path: "/setting/channel-usage",
          icon: "receipt",
        },
        {
          title: "Ticket",
          desc: "การตั้งค่า Ticket",
          path: "/setting/ticket",
          icon: "receipt",
        },
        {
          title: "Team",
          desc: "การตั้งค่า Team",
          path: "/setting/team",
          icon: "users",
        },

        {
          title: "Manufacturer",
          desc: "การตั้งค่า Manufacturer",
          path: "/setting/brand",
          icon: "info-circle",
        },
        // {
        //   title: "Inventory",
        //   desc: "การตั้งค่าการย้ายสินค้า",
        //   path: "/setting/inventory",
        //   icon: "boxes",
        // },

        {
          title: "Line Rich Menu",
          desc: "การตั้งค่า Rich Menu",
          path: "/setting/rich-menu",
          icon: "table",
        },
        {
          title: "receipt format",
          desc: "ตั้งค่ารูปแบบใบเสร็จ",
          path: "/setting/receipt-format",
          icon: "table",
        },
        {
          title: "OTP LOG",
          desc: "ประวัติการส่ง OTP",
          path: "/setting/otp-log",
          icon: "inbox",
        },
        {
          title: "Webhook",
          desc: "การตั้งค่า Webhook",
          path: "/setting/webhook",
          icon: "globe",
        },
        {
          title: "USER MANUAL",
          desc: "ตั้งค่าทั่วไป",
          path: "/setting/user-manual",
          icon: "cog",
        },
        {
          title: "Request Purchase",
          desc: "การตั้งค่า Request Purchase",
          path: "/setting/request-purchase",
          icon: "cart-plus",
        },
        {
          title: "System",
          desc: "ตั้งค่าทั่วไป",
          path: "/setting/general",
          icon: "cog",
        },
      ],
    };
  },
  computed: {
    filterItems() {
      return this.search
        ? this.menu.filter(
            (el) =>
              el.title.toLowerCase().includes(this.search.toLowerCase()) ||
              el.desc.includes(this.search.toLowerCase())
          )
        : this.menu;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-icon {
  background-color: var(--theme-secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 15px;
  text-align: center;
}
.content {
  position: absolute;
  top: 5px;
  margin-left: 60px;
}
.ft-10 {
  font-size: 10px;
}
.ft-weight {
  font-weight: 600;
}
</style>
